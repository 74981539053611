import moment from "moment";
import store from "store";

export default (batchesMap) => {
  const instituteId = store.get("instituteId");
  return [
    {
      field: "index",
      headerName: "S No",
      editable: false,
      width: 80,
      valueGetter: (params) => {
        const ids = params.api.getAllRowIds();
        return ids.indexOf(params.id) + 1;
      },
    },

    { field: "admissionNo", headerName: "Admission No", width: 120 },
    {
      field: "isTcGenerated",
      headerName: "Is TcGenerated",
      width: 120,
      valueGetter: (params) => (params.row.isTcGenerated ? "Yes" : "No"),
    },

    {
      field: "fullName",
      headerName:
        instituteId === "645b4332b98a5dc4ce54256d" ? "SurName" : "First Name",
      editable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "lastName",
      headerName:
        instituteId === "645b4332b98a5dc4ce54256d" ? "Name" : "Last Name",
      editable: true,
      flex: 1,
      minWidth: 120,
    },

    {
      field: "batch",
      headerName: "Batch",
      editable: true,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => batchesMap?.[params.row.batch]?.batchName,
    },

    {
      field: "gender",
      headerName: "Gender",
      editable: true,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "dateOfAdmission",
      headerName: "Date of Admission",
      editable: true,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        moment(params.row.dateOfAdmission).format("MM-DD-YYYY"),
    },
    {
      field: "dateOActuallyLeftTheCollege",
      headerName: "Date Of Left",
      editable: true,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        moment(params.row.dateOActuallyLeftTheCollege).format("MM-DD-YYYY"),
    },
  ];
};
