import { takeLatest, put, all, call } from "redux-saga/effects";
// actions
import store from "store";
import {
  fetchAcademicYearsSuccess,
  fetchAcademicYearsFailure,
  updateAcademicYearsList,
  updateAcademicYearFailure,
  updateAcademicYearSuccess,
  resetAcademicYearProcess,
  createAcademicYearSuccess,
} from "./academicYear.slice";
import {
  setInitializationStatus,
  setAcademicYear,
  setStartOrEndOfBackdropProcess,
  setProcessStatus,
  setCurrentAcademicYear,
} from "../../app/app.slice";
import { fetchZonesStart } from "../zones/zones.slice";
import { fetchBranchesStart } from "../branches/branches.slice";
import { fetchBatchesStart } from "../batches/batches.slice";
// api
import {
  getAcademicYears,
  updatAcademicYear,
  createAcademicYear,
} from "../../../APIs/organization/AcademicYear";
// redux store
import storeRedux from "../../store";
// utils
import getTimeFromDate from "../../../utils/general.utils/getTime";
import { getState } from "../../selector.utils";
// selectors
import { selectIsAppInitialized } from "../../app/app.selectors";
import { create } from "../../app/app.utils";

const getStuffToUpdate = () => {
  const { academicYearsManagement } = storeRedux.getState();
  const stuff = academicYearsManagement.stuffToUpdate;
  return stuff;
};
export function* createAcademicYearAsync({ payload }) {
  yield create({
    callName: "create_Academic_year",
    inputs: payload,
    api: createAcademicYear,
    *handleRes(item) {
      yield put(createAcademicYearSuccess(item));
    },
    resName: "saved",
  });
}
export function* fetchYearsAsync() {
  const isAppInitialized = selectIsAppInitialized(getState());
  const user = store.get("user");
  const isCampaign = user?.designation === "CAMPAIGN";

  try {
    const res = yield getAcademicYears();
    if (res) {
      const items = res.result.docs;
      yield put(fetchAcademicYearsSuccess(items));
      const current = items.find(
        (obj) =>
          (!isCampaign ? obj.isCurrentAcademicYear : obj.isPreAdmissionYear) ===
          true
      );

      if (!isAppInitialized) {
        yield put(setAcademicYear(current));
        const query = {
          start: getTimeFromDate(current.startDate),
          end: getTimeFromDate(current.endDate),
          showFeedback: false,
          noTimeFilter: true,
          ay: current.code,
        };
        yield put(fetchZonesStart(query));
        yield put(fetchBranchesStart(query));
        yield put(fetchBatchesStart(query));
      }
      yield put(setCurrentAcademicYear(current));
      yield put(setInitializationStatus({ fetchedAcademicYears: true }));
      yield put(resetAcademicYearProcess());
    }
  } catch (error) {
    yield put(fetchAcademicYearsFailure(error.message));
  }
}

export function* updateAcademicYearMappingAsync() {
  const stuffToUpdate = getStuffToUpdate();
  const { id, ...stuff } = stuffToUpdate;
  try {
    yield put(setStartOrEndOfBackdropProcess(true));
    const res = yield updatAcademicYear(id, { ...stuff });
    if (res) {
      yield put(updateAcademicYearsList(res.updatedAcademicYear));
      yield put(
        setProcessStatus({
          name: "academic year mapping",
          message: res.message,
          result: "success",
        })
      );
    }
  } catch (error) {
    yield put(
      setProcessStatus({
        name: "academic year mapping",
        message: error.message,
        result: "error",
      })
    );
  }
  yield put(setStartOrEndOfBackdropProcess(false));
}

export function* updateAcademicYearAsync() {
  const stuffToUpdate = getStuffToUpdate();
  const { id, ...stuff } = stuffToUpdate;
  try {
    const res = yield updatAcademicYear(id, { ...stuff });
    if (res) {
      yield put(updateAcademicYearSuccess());
      yield put(updateAcademicYearsList(res.updatedAcademicYear));
      if (res.updatedAcademicYear.isCurrentAcademicYear) {
        yield put(setCurrentAcademicYear(res.updatedAcademicYear));
      }
    }
  } catch (error) {
    yield put(updateAcademicYearFailure(error.message));
  }
}

export function* onFetchAcademicYearStart() {
  yield takeLatest("year/fetchAcademicYearsStart", fetchYearsAsync);
}
export function* onCreateSequenceStart() {
  yield takeLatest("SEQUENCE/createAcademicYearStart", createAcademicYearAsync);
}

export function* onUpdateAcademicYearMapping() {
  yield takeLatest(
    "year/updateAcademicYearMapping",
    updateAcademicYearMappingAsync
  );
}

export function* onUpdateAcademicYearStart() {
  yield takeLatest("year/updateAcademicYearStart", updateAcademicYearAsync);
}

export default function* academicYearSaga() {
  yield all([
    call(onFetchAcademicYearStart),
    call(onUpdateAcademicYearMapping),
    call(onUpdateAcademicYearStart),
  ]);
}
